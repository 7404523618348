import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Admin } from './admin';
import {
  Basket,
  Condition,
  Home,
  Login,
  Logout,
  MultiSearch,
  NotFound,
  PrivateRoute,
  Protected,
  SignUp,
  Taxon,
} from './views';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// const useStyles = makeStyles((theme) => ({
//   app: {
//     textAlign: 'center',
//   },
//   header: {
//     backgroundColor: '#282c34',
//     minHeight: '100vh',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     fontSize: 'calc(10px + 2vmin)',
//     color: 'white',
//   },
// }));

export const AppRoutes: FC = () => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={darkTheme}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            minHeight: '100vh',
            fontSize: 'calc(10px + 2vmin)',
            backgroundColor: '#282c34',
            color: 'white',
          }}
        >
          <Routes>
            <Route path="/admin/*" element={<Admin />} />
            <Route path="/taxon/:id" element={<Taxon />} />
            <Route path="/multi/*" element={<MultiSearch />} />
            <Route path="/condition/*" element={<Condition />} />
            <Route path="/basket/*" element={<Basket />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/logout" element={<Logout />} />
            <Route element={<PrivateRoute />}>
              <Route path="/protected" element={<Protected />} />
            </Route>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </>
  );
};
