import {
  AppBar,
  Grid,
  Link,
  List,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { sanitize } from 'dompurify';
import { FC, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { ApiError, Taxon, TaxonService } from '../client';
import { isAuthenticated } from '../utils/auth';
import { encodeURISciName, useThrottle } from '../utils/index';
import { BasketDrawer } from './BasketDrawer';

export const Home: FC = () => {
  const [q, setQ] = useQueryState('q', '');
  const qRef = useRef<HTMLInputElement>(null);

  const queryThrottled = useThrottle(q, 200);

  useEffect(() => {
    if (q) {
      document.title = sanitize(`Herbascope - ${q}`);
    } else {
      document.title = 'Herbascope';
    }
  }, [q]);

  const query = useQuery({
    queryKey: ['taxonSearchApiV1TaxonSearchGet', queryThrottled],
    queryFn: () =>
      TaxonService.taxonSearchApiV1TaxonSearchGet({ q: queryThrottled }),
  });

  return (
    <Grid container direction="column">
      <AppBar position="static" sx={{ zIndex: 2000 }}>
        <Toolbar>
          <Grid container flex={1} justifyContent="center" alignItems="center">
            <Link
              to="/"
              underline="none"
              color="inherit"
              component={RouterLink}
              onClick={() => {
                qRef.current?.focus();
              }}
            >
              <Typography variant="h6" noWrap>
                Herbascope
              </Typography>
            </Link>
            <Grid
              container
              item
              flex={1}
              justifyContent="center"
              alignItems="center"
            >
              <TextField
                inputRef={qRef}
                id="outlined-basic"
                placeholder="Lookup species..."
                variant="outlined"
                autoFocus
                onFocus={(event) => {
                  event.target.select();
                }}
                sx={{ marginY: 2 }}
                value={q}
                onChange={(v) => {
                  setQ(v.target.value);
                }}
              />
              <CircularProgress
                sx={{
                  opacity: q !== '' && query.isPending ? undefined : 0,
                  marginLeft: 2,
                }}
                size=".8em"
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        sx={{ paddingTop: 2, paddingX: 2 }}
      >
        {q === '' ? <HomeStuff /> : <SearchStuff query={query} />}
      </Grid>
      <BasketDrawer />
    </Grid>
  );
};

const HomeStuff: FC = () => {
  const showCruft = false;
  return (
    <Grid container item direction="column" xs={12} sm={8} md={6}>
      {showCruft && (
        <Link to="/admin" component={RouterLink}>
          Admin Dashboard
        </Link>
      )}
      {showCruft && (
        <Link to="/protected" component={RouterLink}>
          Protected Route
        </Link>
      )}
      <Link to="/condition" component={RouterLink}>
        Search by cultural conditions
      </Link>
      <Link to="/multi" component={RouterLink}>
        Multi-search
      </Link>
      <Link to="/basket" component={RouterLink}>
        Basket
      </Link>
      {showCruft &&
        (isAuthenticated() ? (
          <Link to="/logout" component={RouterLink}>
            Logout
          </Link>
        ) : (
          <>
            <Link to="/login" component={RouterLink}>
              Login
            </Link>
            <Link to="/signup" component={RouterLink}>
              Sign Up
            </Link>
          </>
        ))}
    </Grid>
  );
};

const SearchStuff: FC<{
  query: UseQueryResult<Taxon[], Error>;
}> = ({ query }) => {
  return (
    <Grid container item direction="column" xs={12} sm={8} md={6}>
      {query.error && (
        <Grid container>
          <Grid item sx={{ mx: 1 }}>
            {(query.error as ApiError).status}
          </Grid>
          <Grid item sx={{ mx: 1 }}>
            {(query.error as ApiError).name}
          </Grid>
          <Grid item sx={{ mx: 1 }}>
            {(query.error as ApiError).message}
          </Grid>
          <Grid item sx={{ mx: 1 }}>
            {(query.error as ApiError).url}
          </Grid>
        </Grid>
      )}
      <List sx={{ backgroundColor: '#37474f' }}>
        {query.isSuccess && (
          <ListItemText>
            {(query.data as Taxon[]).slice(0, 100).map((t, i) => (
              <SearchResult r={t} i={i} />
            ))}
          </ListItemText>
        )}
      </List>
    </Grid>
  );
};

const SearchResult: FC<{
  r: Taxon;
  i?: number;
}> = ({ r, i }) => {
  return (
    <Link
      key={r.name_sci}
      to={`/taxon/${encodeURISciName(r.name_sci || '')}`}
      underline="none"
      component={RouterLink}
    >
      <ListItemText
        primary={r.name_sci}
        secondary={r.name_comm}
        sx={{
          paddingLeft: 2,
          backgroundColor: (i || 0) % 2 === 0 ? undefined : '#455a64',
        }}
      />
    </Link>
  );
};
SearchResult.defaultProps = { i: 0 };
