import { without } from 'lodash';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type State = {
  persistent: {
    basket: string;
    baskets: { [key: string]: string[] };
  };
};

type Actions = {
  pickTaxon: (name_sci: string) => void;
  dropTaxon: (name_sci: string) => void;
};

export const hasBasketTaxon = (state: State, name_sci: string): boolean => {
  const basket = state.persistent.baskets[state.persistent.basket] || [];
  return basket.includes(name_sci);
};

/* eslint-disable no-param-reassign */
export const useStore = create<State & Actions>()(
  persist(
    immer((set) => ({
      pickTaxon: (name_sci) => {
        if (!name_sci) {
          return;
        }
        set((state) => {
          // eslint-disable-next-line no-multi-assign
          const basket = (state.persistent.baskets[state.persistent.basket] ||=
            []);
          if (basket.includes(name_sci)) return;
          basket.push(name_sci);
        });
      },
      dropTaxon: (name_sci) => {
        set((state) => {
          state.persistent.baskets[state.persistent.basket] = without(
            state.persistent.baskets[state.persistent.basket],
            name_sci
          );
        });
      },
      persistent: {
        basket: 'main',
        baskets: { main: [] },
      },
    })),
    {
      name: 'herbascope_store_state',
      partialize: (state) => ({
        persistent: state.persistent,
      }),
      version: 5, // Change to wipe out store.
    }
  )
);
/* eslint-enable no-param-reassign */
