import { Grid, Tooltip, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { FC, useState } from 'react';
import { squeezeBloomtime } from '../utils';

const Tp = Typography;

export const BloomRow: FC<{
  // list of months like [['jul','aug'], ['jul'], []]
  bloomtime: string[][];
  variant?: 'basic' | 'count';
  sx?: SxProps<Theme>;
  onHover?: (arg0: string | undefined) => void;
}> = ({ bloomtime, variant, sx, onHover }) => {
  const [hover, setHover] = useState<string | undefined>();
  function hoverOn(s: string) {
    onHover?.(s);
    setHover(s);
  }
  function hoverOff(s: string) {
    if (s === hover) {
      onHover?.(undefined);
      setHover(undefined);
    }
  }
  const { unknown, months: squeezed } = squeezeBloomtime(bloomtime);
  if (unknown === 0) {
    hoverOff('unknown');
  }
  const max = Math.max.apply(
    null,
    squeezed.map((x) => x.blooming)
  );
  return (
    <Grid container direction="row" sx={sx}>
      {squeezed.map((x) => (
        <Grid
          item
          key={x.month}
          onMouseEnter={() => hoverOn(x.monthShort)}
          onMouseLeave={() => hoverOff(x.monthShort)}
        >
          <Tooltip title={x.month}>
            <Grid container direction="column" alignItems="center">
              {variant === 'basic' && (
                <Grid
                  item
                  sx={{
                    backgroundColor: x.blooming ? 'white' : undefined,
                    border: x.blooming > 0 ? undefined : 1,
                    borderColor: 'white',
                    width: '.8em',
                    height: '.8em',
                    marginX: '.15em',
                  }}
                />
              )}
              {variant === 'count' && (
                <Grid
                  container
                  item
                  justifyContent="center"
                  sx={{
                    border: 1,
                    borderColor: `hsl(0,0%,${(x.blooming / max) * 100}%)`,
                    width: '.8em',
                    height: '.8em',
                    marginX: '.15em',
                  }}
                >
                  {x.blooming > 0 && <Tp>{x.blooming}</Tp>}
                </Grid>
              )}
              <Tp variant="caption">{x.letter}</Tp>
            </Grid>
          </Tooltip>
        </Grid>
      ))}
      {unknown > 0 && (
        <Grid
          item
          sx={{ marginLeft: 1 }}
          onMouseEnter={() => hoverOn('unknown')}
          onMouseLeave={() => hoverOff('unknown')}
        >
          <Tooltip title="Unknown bloom time">
            <Grid container direction="column" alignItems="center">
              <Grid
                container
                item
                justifyContent="center"
                sx={{
                  border: 1,
                  width: '.8em',
                  height: '.8em',
                  marginX: '.15em',
                }}
              >
                <Tp>{unknown}</Tp>
              </Grid>

              <Tp variant="caption">?</Tp>
            </Grid>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};
BloomRow.defaultProps = { variant: 'basic', sx: undefined, onHover: undefined };
