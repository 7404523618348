/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Taxon } from '../models/Taxon';
import type { TaxonDetail } from '../models/TaxonDetail';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxonService {

    /**
     * Taxon Filter
     * @returns Taxon Successful Response
     * @throws ApiError
     */
    public static taxonFilterApiV1TaxonFilterGet({
        heightMin,
        heightMax,
        widthMin,
        widthMax,
        sunAllowed,
        waterAllowed,
    }: {
        heightMin?: number,
        heightMax?: number,
        widthMin?: number,
        widthMax?: number,
        sunAllowed?: string,
        waterAllowed?: string,
    }): CancelablePromise<Array<Taxon>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/taxon/filter',
            query: {
                'height_min': heightMin,
                'height_max': heightMax,
                'width_min': widthMin,
                'width_max': widthMax,
                'sun_allowed': sunAllowed,
                'water_allowed': waterAllowed,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Taxon Search
     * @returns Taxon Successful Response
     * @throws ApiError
     */
    public static taxonSearchApiV1TaxonSearchGet({
        q = '',
    }: {
        q?: string,
    }): CancelablePromise<Array<Taxon>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/taxon/search',
            query: {
                'q': q,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Taxon Detail
     * @returns TaxonDetail Successful Response
     * @throws ApiError
     */
    public static taxonDetailApiV1TaxonDetailGet({
        q = '',
    }: {
        q?: string,
    }): CancelablePromise<TaxonDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/taxon/detail',
            query: {
                'q': q,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
