import { Link } from '@mui/material';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const NotFound: FC = () => {
  return (
    <>
      <p>Page not found</p>
      <Link to="/" component={RouterLink}>
        back to root
      </Link>
    </>
  );
};
