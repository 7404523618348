import { Button, Drawer, Grid } from '@mui/material';
import { FC } from 'react';
import { useStore } from '../store';

export const BasketDrawer: FC = () => {
  const store = useStore((state) => state);
  return (
    <Drawer variant="permanent" anchor="bottom">
      <Grid container sx={{ padding: 2 }}>
        {store.persistent.baskets[store.persistent.basket].map((x) => (
          <Button
            key={x}
            variant="outlined"
            sx={{ marginX: 1 }}
            onClick={() => store.dropTaxon(x)}
          >
            {x}
          </Button>
        ))}
      </Grid>
    </Drawer>
  );
};
