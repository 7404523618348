import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RemoveIcon from '@mui/icons-material/Remove';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ApiError, TaxonDetail, TaxonService } from '../client';
import { hasBasketTaxon, useStore } from '../store';
import { BloomRow } from './BloomRow';

function rehydrateSciName(s: string) {
  return (s.charAt(0).toUpperCase() + s.slice(1)).replace(/-/g, ' ');
}

function rehydrateSourceName(s: string | undefined): string {
  switch (s) {
    case 'bplant':
      return 'bplant.org';
    case 'gobotany':
      return 'Go Botany';
    case 'mobot':
      return 'Missouri Botanical Garden';
    case 'ncsu':
      return 'North Carolina Plant Toolbox';
    case 'pfaf':
      return 'Plants For A Future';
    case 'spruce':
      return 'The Spruce';
    case 'prairiemooncultural':
      return 'Praire Moon';
    default:
      return s || '';
  }
}

const sourceFavIcons = {
  bplant: 'https://bplant.org/images/bplant-favicon.png',
  gobotany:
    'https://gobotany.nativeplanttrust.org/static/images/branding/favicon.png',
  mobot: 'https://www.missouribotanicalgarden.org/favicon.ico',
  ncsu: 'https://brand.ces.ncsu.edu/images/icons/wolficon.svg',
  pfaf: 'https://pfaf.org/pfaf.ico',
  spruce: 'https://www.thespruce.com/favicon.ico',
  prairiemooncultural:
    'https://www.prairiemoon.com/mm5/themes/genesis/branches/production/public/images/favicon.png',
};

const Tp = Typography;

export const Taxon: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const store = useStore((state) => state);

  const query = useQuery({
    queryKey: ['taxonDetailApiV1TaxonDetailGet', id],
    queryFn: () =>
      TaxonService.taxonDetailApiV1TaxonDetailGet({
        q: id,
      }),
    enabled: (id?.length || 0) > 0,
  });

  const nameSci = rehydrateSciName(query.data?.name_sci || id || '');

  return (
    <Grid container direction="column">
      <AppBar position="static" sx={{ zIndex: 2000 }}>
        <Toolbar>
          <Grid container justifyContent="center">
            <Link
              to=".."
              underline="none"
              color="inherit"
              component={RouterLink}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              <ArrowBackIosNewIcon />
            </Link>
            <Grid container item flex={1} justifyContent="center">
              <Typography variant="h6" noWrap component="div">
                {nameSci}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        sx={{ paddingTop: 2, paddingX: 2 }}
      >
        <Grid container item direction="column" xs={12} sm={8} md={6}>
          {query.error && (
            <Paper sx={{ marginBottom: 2 }}>
              <Grid container sx={{ color: 'red' }}>
                <Grid item sx={{ mx: 1 }}>
                  {(query.error as ApiError).status}
                </Grid>
                <Grid item sx={{ mx: 1 }}>
                  {(query.error as ApiError).name}
                </Grid>
                <Grid item sx={{ mx: 1 }}>
                  {(query.error as ApiError).message}
                </Grid>
                <Grid item sx={{ mx: 1 }}>
                  {(query.error as ApiError).url}
                </Grid>
              </Grid>
            </Paper>
          )}
          <Grid container direction="column">
            {(query.data?.imgs?.length || 0) >= 1 && (
              <Grid item>
                <img
                  alt={id}
                  height={320}
                  src={query.data?.imgs?.[0]?.url || ''}
                />
              </Grid>
            )}
            <TabRow
              title={`Common name${
                (query.data?.name_comm?.length || 2) > 1 ? 's' : ''
              }`}
            >
              <Tp>{query.data?.name_comm?.join(', ')}</Tp>
            </TabRow>
            <TabRow title="Height">
              <Tooltip
                disableInteractive
                title={`According to: ${rehydrateSourceName(
                  query.data?.h_source
                )}`}
              >
                <Tp>{height(query.data)}</Tp>
              </Tooltip>
            </TabRow>
            <TabRow title="Spread">
              <Tooltip
                disableInteractive
                title={`According to: ${rehydrateSourceName(
                  query.data?.w_source
                )}`}
              >
                <Tp>{spread(query.data)}</Tp>
              </Tooltip>
            </TabRow>
            <TabRow title="Sun">
              <Tooltip
                title={`According to ${rehydrateSourceName(
                  query.data?.sun_source
                )}`}
              >
                <Grid container direction="column">
                  <Grid item>
                    {query.data?.sun_full_sun && <Tp>Full sun</Tp>}{' '}
                  </Grid>
                  <Grid item>
                    {query.data?.sun_partial && <Tp>Partial</Tp>}
                  </Grid>
                  <Grid item>
                    {query.data?.sun_full_shade && <Tp>Full shade</Tp>}
                  </Grid>
                  <Grid item>
                    {query.data?.sun_dappled && <Tp>Dappled</Tp>}
                  </Grid>
                  <Grid item />
                </Grid>
              </Tooltip>
            </TabRow>
            <TabRow title="Water">
              <Tooltip
                title={`According to ${(query.data?.water_sources || [])
                  .map(rehydrateSourceName)
                  .join(', ')}`}
              >
                <Grid container direction="column">
                  <Grid item>{query.data?.water_dry && <Tp>Dry</Tp>} </Grid>
                  <Grid item>
                    {query.data?.water_medium && <Tp>Medium</Tp>}
                  </Grid>
                  <Grid item>{query.data?.water_wet && <Tp>Wet</Tp>}</Grid>
                  <Grid item>
                    {query.data?.water_water_plant && <Tp>Water plant</Tp>}
                  </Grid>
                  <Grid item />
                </Grid>
              </Tooltip>
            </TabRow>
            {!!query.data?.edibility && (
              <TabRow title="Edibility">
                <Tooltip
                  title={`Edibility: ${query.data?.edibility} out of 5 (according to PFAF)`}
                >
                  <Grid container>
                    <Grid item>
                      <RestaurantIcon />
                    </Grid>
                    <Grid item>
                      <Tp>{query.data?.edibility}</Tp>
                    </Grid>
                  </Grid>
                </Tooltip>
              </TabRow>
            )}
            <TabRow title="Native">
              {query.data?.native ? (
                <Tooltip
                  title={`According to ${rehydrateSourceName(
                    query.data?.native_source
                  )}`}
                >
                  <Typography>{query.data?.native}</Typography>
                </Tooltip>
              ) : (
                <Typography>Unknown</Typography>
              )}
            </TabRow>
            {(query.data?.bloomtime?.length || 0) > 0 && (
              <TabRow title="Bloom times">
                <BloomRow bloomtime={[query.data?.bloomtime || []]} />
              </TabRow>
            )}
            <TabRow title="Links">
              {Object.entries(query.data?.source_links || {}).map(([k, v]) => (
                <Tp key={k}>
                  <Link href={v} target="_blank">
                    {k in sourceFavIcons ? (
                      <img
                        style={{
                          width: '1em',
                          height: '1em',
                          marginRight: '.4em',
                          backgroundColor: 'white',
                        }}
                        src={(sourceFavIcons as { [key: string]: string })[k]}
                        alt={`favicon for ${rehydrateSourceName(k)}`}
                      />
                    ) : (
                      <OpenInNewIcon
                        fontSize="inherit"
                        sx={{ marginRight: '.4em', backgroundColor: 'white' }}
                      />
                    )}
                    {rehydrateSourceName(k)}
                  </Link>
                </Tp>
              ))}
            </TabRow>
            <TabRow title="Basket">
              {query.isSuccess &&
                (!hasBasketTaxon(store, nameSci) ? (
                  <Button
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      store.pickTaxon(nameSci);
                    }}
                  >
                    <AddIcon /> Add to basket
                  </Button>
                ) : (
                  <Button
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      store.dropTaxon(nameSci);
                    }}
                  >
                    <RemoveIcon /> Remove from basket
                  </Button>
                ))}
            </TabRow>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TabRow: FC<{
  title: string;
  children?: any;
}> = ({ title, children }) => (
  <Grid
    item
    container
    direction="row"
    sx={{ borderBottom: 1, borderColor: '#777', paddingY: 0.3 }}
  >
    <Grid item xs={3}>
      <Typography>
        <Box component="span" fontWeight="fontWeightMedium">
          {title}
        </Box>
      </Typography>
    </Grid>
    <Grid item xs={9}>
      {children}
    </Grid>
  </Grid>
);
TabRow.defaultProps = { children: null };

function height(d: TaxonDetail | null | undefined): string {
  return sizeRange(d?.h_min, d?.h_max);
}

function spread(d: TaxonDetail | null | undefined): string {
  return sizeRange(d?.w_min, d?.w_max);
}

function sizeRange(min: number | undefined, max: number | undefined): string {
  if (isNumber(max) && max === min) return numFtOrIn(max);
  if (!isNumber(min) && isNumber(max)) return `up to ${numFtOrIn(max)}`;
  if (isNumber(min) && !isNumber(max)) return `${numFtOrIn(min)} or more`;
  if (isNumber(min) && isNumber(max))
    return `${numFtOrIn(min)} to ${numFtOrIn(max)}`;
  return 'unknown';
}

function numFtOrIn(v: number) {
  if (v === 12) return `1 ft`;
  if (v === 24) return `2 ft`;
  if (v === 36) return `3 ft`;
  if (v < 36) return `${v}"`;
  return `${v / 12} ft`;
}

function isNumber(v: unknown): v is number {
  return typeof v === 'number';
}
