import { Face, Fingerprint } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';

import { isAuthenticated, login } from '../utils/auth';

const styles = { button: { textTransform: 'none' } };

export const Login: FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const theme = useTheme();

  const handleSubmit = async (_: React.MouseEvent) => {
    setError('');
    try {
      const data = await login(email, password);

      if (data) {
        navigate('/');
      }
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(String(err));
      }
    }
  };

  return isAuthenticated() ? (
    <Navigate to="/" replace />
  ) : (
    <Paper sx={{ padding: theme.spacing(3) }}>
      <Grid container spacing={8} alignItems="flex-end">
        <Grid item>
          <Face />
        </Grid>
        <Grid item md sm xs>
          <TextField
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.currentTarget.value)
            }
            fullWidth
            autoFocus
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={8} alignItems="flex-end">
        <Grid item>
          <Fingerprint />
        </Grid>
        <Grid item md sm xs>
          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.currentTarget.value)
            }
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <br />
      <Grid container alignItems="center">
        {error && (
          <Grid item>
            <h2>Error: {error}</h2>
          </Grid>
        )}
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Remember me"
          />
        </Grid>
        <Grid item>
          <Button
            disableFocusRipple
            disableRipple
            variant="text"
            color="primary"
            sx={styles.button}
          >
            Forgot password ?
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ marginTop: 10 }}>
        {' '}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate('/signup')}
          sx={styles.button}
        >
          Sign Up
        </Button>{' '}
        &nbsp;
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
          sx={styles.button}
        >
          Login
        </Button>
      </Grid>
    </Paper>
  );
};
