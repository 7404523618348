import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import { logout } from '../utils/auth';

export const Logout: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    logout();
    navigate('/');
  });

  return <Navigate to="/" replace />;
};
