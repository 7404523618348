import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  AppBar,
  CircularProgress,
  Grid,
  Link,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { ApiError, TaxonService } from '../client';
import { encodeURISciName } from '../utils';
import { BloomRow } from './BloomRow';

const Tp = Typography;

export function MultiSearch() {
  const navigate = useNavigate();
  const [state, setState] = useImmer({
    text: '',
    hoveredBloomtime: undefined as string | undefined,
  });

  const lines = state.text.split('\n').map((term) => term.trim());

  const qs = useQueries({
    queries: lines
      .filter((term) => !!term)
      .map((name_sci) => ({
        queryKey: ['taxonDetailApiV1TaxonDetailGet', name_sci],
        queryFn: () =>
          TaxonService.taxonDetailApiV1TaxonDetailGet({
            q: name_sci,
          }).then((tx) => {
            return { ...tx, searchedFor: name_sci };
          }),
      })),
    combine: (results) => {
      if (results.every((r) => r.status === 'success'))
        return {
          status: 'success' as 'success',
          data: results.map((r) => r.data),
          raw: results,
        };
      if (results.some((r) => r.status === 'error'))
        return {
          status: 'error' as 'error',
          raw: results,
        };
      return {
        status: 'pending' as 'pending',
        raw: results,
      };
    },
  });

  return (
    <Grid container direction="column">
      <AppBar position="sticky" sx={{ zIndex: 2000 }}>
        <Toolbar>
          <Grid container direction="row" alignItems="center">
            <Link
              to=".."
              underline="none"
              color="inherit"
              component={RouterLink}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              <ArrowBackIosNewIcon />
            </Link>
            <Grid container item flex={1} justifyContent="center">
              <Tp>Mutliple Search</Tp>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        sx={{ paddingTop: 2, paddingX: 2 }}
      >
        <Grid container item direction="column" xs={12} sm={8} md={6}>
          <Tp>Search for multiple plants:</Tp>
          <Grid container item direction="row">
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                multiline
                autoFocus
                sx={{
                  marginY: 2,
                  width: '100%',
                  paddingRight: 1,
                }}
                value={state.text}
                spellCheck={false}
                onChange={(e) =>
                  setState((draft) => {
                    draft.text = e.target.value;
                  })
                }
              />
            </Grid>
            <Grid
              container
              item
              xs={6}
              sx={{ paddingTop: 4 }}
              direction="column"
            >
              {qs.raw.map((x, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item key={i}>
                  {x.isPending && <CircularProgress />}
                  {x.error && (
                    <Paper sx={{ marginBottom: 2 }}>
                      <Grid container sx={{ color: 'red' }}>
                        <Grid item sx={{ mx: 1 }}>
                          {(x.error as ApiError).status}
                        </Grid>
                        <Grid item sx={{ mx: 1 }}>
                          {(x.error as ApiError).name}
                        </Grid>
                        <Grid item sx={{ mx: 1 }}>
                          {(x.error as ApiError).message}
                        </Grid>
                        <Grid item sx={{ mx: 1 }}>
                          {(x.error as ApiError).url}
                        </Grid>
                      </Grid>
                    </Paper>
                  )}
                  {x.isSuccess && (
                    <Link
                      to={`/taxon/${encodeURISciName(x.data.name_sci || '')}`}
                      component={RouterLink}
                    >
                      <Tp
                        sx={{
                          color:
                            x.data.bloomtime?.includes(
                              state.hoveredBloomtime || ''
                            ) ||
                            ((x.data.bloomtime || []).length === 0 &&
                              state.hoveredBloomtime === 'unknown')
                              ? 'orange'
                              : undefined,
                        }}
                      >
                        {x.data.name_sci}
                        <SearchResultAddendum
                          searchedFor={x.data.searchedFor || ''}
                          name_sci={x.data.name_sci || ''}
                          name_comm={x.data.name_comm || []}
                        />
                      </Tp>
                    </Link>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Tp>Bloom times</Tp>
          {qs.status === 'success' && (
            <BloomRow
              variant="count"
              onHover={(x) =>
                setState((draft) => {
                  draft.hoveredBloomtime = x;
                })
              }
              bloomtime={qs.data.map((d) => d?.bloomtime || [])}
            />
          )}
          {qs.status === 'pending' && '[loading]'}
          {qs.status === 'error' && '[error]'}
        </Grid>
      </Grid>
      {/* <BasketDrawer /> */}
    </Grid>
  );
}

const SearchResultAddendum: FC<{
  searchedFor: string;
  name_sci: string;
  name_comm: string[];
}> = ({ searchedFor, name_sci, name_comm }) => {
  return normalizedCompare(searchedFor, name_sci) ||
    name_comm.some((c) => normalizedCompare(searchedFor, c)) ? null : (
    <> *</>
  );
};

function normalizedCompare(s1: string, s2: string) {
  return normalize(s1) === normalize(s2);
}

function normalize(s: string): string {
  return s.replace(/\s+/g, ' ').replace(/-|'/g, '').toLowerCase();
}
