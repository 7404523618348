import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './Routes';
import './index.css';

const millisecondsPerHour = 1000 * 60 * 60;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * millisecondsPerHour,
      gcTime: 24 * millisecondsPerHour,
    },
  },
});

const syncStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: syncStoragePersister, buster: '9' }}
    >
      <Router>
        <AppRoutes />
      </Router>
    </PersistQueryClientProvider>
  </StrictMode>
);
