import { AppBar, Button, Grid, Link, Toolbar, Typography } from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TaxonService } from '../client';
import { useStore } from '../store';
import { BloomRow } from './BloomRow';

export const Basket: FC = () => {
  const store = useStore((state) => state);
  const basket = store.persistent.baskets[store.persistent.basket];

  const qs = useQueries({
    queries: basket.map((name_sci) => ({
      queryKey: ['taxonDetailApiV1TaxonDetailGet', name_sci],
      queryFn: () =>
        TaxonService.taxonDetailApiV1TaxonDetailGet({
          q: name_sci,
        }),
    })),
    combine: (results) => {
      if (results.every((r) => r.status === 'success'))
        return {
          status: 'success' as 'success',
          data: results.map((r) => r.data),
        };
      if (results.some((r) => r.status === 'error'))
        return {
          status: 'error' as 'error',
        };
      return {
        status: 'pending' as 'pending',
      };
    },
  });

  return (
    <Grid container direction="column">
      <AppBar position="static" sx={{ zIndex: 2000 }}>
        <Toolbar>
          <Link to="/" underline="none" color="inherit" component={RouterLink}>
            <Typography variant="h6" noWrap component="div">
              Basket {store.persistent.basket}
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        sx={{ paddingTop: 2, paddingX: 2 }}
      >
        <Grid container item direction="column" xs={12} sm={8} md={6}>
          <Grid container sx={{ padding: 2 }}>
            {basket.map((name_sci) => (
              <Item key={name_sci} name_sci={name_sci} />
            ))}
            {qs.status === 'success' && (
              <BloomRow
                variant="count"
                bloomtime={qs.data.map((d) => d?.bloomtime || [])}
                sx={{ marginTop: 2 }}
              />
            )}
            {qs.status === 'pending' && '[loading]'}
            {qs.status === 'error' && '[error]'}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Item: FC<{
  name_sci: string;
}> = ({ name_sci }) => {
  const store = useStore((state) => state);

  return (
    <Button
      variant="outlined"
      sx={{ marginX: 1 }}
      onClick={() => store.dropTaxon(name_sci)}
      //   onClick={() => navigate(`/taxon/${name_sci}`)}
    >
      {name_sci}
    </Button>
  );
};
